<template>
    <div class="">
        <van-sticky>
            <van-search @input="search" v-model="key" shape="round" placeholder="请输入搜索内容" />
        </van-sticky>
        <div class="-mt-10px px-16px">
            <van-list v-model="loading" :immediate-check="false" :finished="finished" finished-text="没有更多了"
                @load="onLoad">
                <ItemComp class="mt-24px" v-for="item in dataList" :key="item.id" :item="item"></ItemComp>
            </van-list>
        </div>
        <loading v-if="isFistLoading"></loading>
    </div>
</template>

<script>
import ItemComp from './components/ItemComp.vue'
import loading from '@/components/loading.vue'
export default {
    components: {
        ItemComp,
        loading
    },
    props: {
        id: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {
            isFistLoading: true,
            key: '',
            num: 1,
            loading: false,
            finished: false,
            dataList: [],
        }
    },
    watch: {
        id: {
            deep: true,
            immediate: true,
            handler(e) {
                if (e!='') {
                    if(this.num==1 && this.dataList.length!=0) return
                    this.isFistLoading = true
                    this.search()
                }
            }
        }
    },
    methods: {
        search() {
            this.num = 1
            this.dataList = []
            this.onLoad()
        },
        async onLoad() {
            try {
                const res = await this.$api.get('/v1/articles', { params: { category_id: this.id, key: this.key, page: this.num, per_page: 20 }, })
                this.dataList = this.dataList.concat(res.data);
                this.num++
                if (res.data.length < 20) {
                    this.finished = true;
                }
                this.loading = false;
            } catch (err) { console.log(err) } finally {
                setTimeout(() => {
                    this.isFistLoading = false
                }, 200);
            }
        },
    }
}
</script>

<style>
</style>