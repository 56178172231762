<template>
  <div class="">
    <van-sticky>
      <div class="grid grid-cols-4 gap-20px px-16px py-20px bg-white">
        <div @click="tabChange(index)" v-for="(item, index) in classList" :key="index"
          class="flex items-center justify-center flex-col text-14px text-hex-000000">
          <van-image lazy-load width="68" height="68" :src="item.cover" />
          <div class="mt-9px">{{ item.name }}</div>
        </div>
      </div>
    </van-sticky>
    <div class="px-16px">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <ItemComp class="mt-16px" v-for="(item,index) in dataList" :key="index" :item="item"></ItemComp>
      </van-list>
    </div>
     <loading v-if="isFistLoading"></loading>
  </div>
</template>

<script>
import ItemComp from './components/ItemComp.vue'
import loading from '@/components/loading.vue'
export default {
  components: {
    ItemComp,
    loading
  },
  data() {
    return {
       isFistLoading:true,
      classList: [],
      num: 1,
      loading: false,
      finished: false,
      dataList: [],
      current:0
    }
  },
  computed:{
   categId(){
    return this.classList[this.current]?.id??''
   }
  },
  methods: {
    tabChange(index){
     if(this.current==index) return
     this.current=index
     this.isFistLoading=true
     this.dataList=[]
     this.num=1
     this.onLoad()
    },
    async onLoad() {
       if(!this.classList.length){
           const {id}=this.$route?.query ?? {}
         const { children } = await this.$api.get(`v1/article-categories/${id}`)
         this.classList = children
       }
      const res = await this.$api.get('/v1/articles', { params: { category_id: this.categId, key: this.key, page: this.num, per_page: 20 }, })
      this.dataList = this.dataList.concat(res.data);
      this.num++
      if (res.data.length < 20) {
        this.finished = true;
      }
      this.loading = false;
      setTimeout(() => {
        this.isFistLoading=false
      }, 200);
    },
  }
}
</script>

<style>
</style>