import router from '../router'
export function jumpByOption(e){
  if(e.jump_type==2){
    if(!e.jump_link) return
    window.location.href=e.jump_link
  }else{
    router.push({
        path:'/detail',
        query: { id:e.id} 
    })
  }
}