<template>
  <div class="">
   <generalist v-if="name!='健康知识'&& name!='产品介绍'" :id="id" ></generalist>
   <health v-if="name=='健康知识'" ></health>
   <introduction v-if="name=='产品介绍'" ></introduction>
  </div>
</template>

<script>
import generalist from '../generalist/index'
import health from '../health/index'
import introduction from '../introduction/index'
export default {
  components:{
    generalist,
    health,
    introduction
  },
  data() {
    return {
      name: '',
      id:''
    }
  },
  activated(){
     const { id, name } = this.$route?.query ?? {}
       this.id=id
       this.name = name
       document.title=name
  },
}
</script>